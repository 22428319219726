import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Typography, Box, Grid, Card, CardContent, CardActions, Button, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';
import { fetchBlogPosts } from '../queries';
import * as Sentry from "@/overrides/sentry.override";

const Blog = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['blogPosts'],
    queryFn: fetchBlogPosts,
  });

  const seoData = {
    title: "Blog",
    description: "Read my latest thoughts and insights on web development and technology.",
    type: "blog",
    url: "https://xyruscode.com.ng/blog",
    image: "https://xyruscode.com.ng/images/blog-og.jpg",
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "Blog",
      name: "Xyrus Code Blog",
      description: "Insights and articles about web development and technology",
      url: "https://xyruscode.com.ng/blog",
    },
  };

  if (isLoading) {
    return (
      <Box className="container mx-auto px-4 py-8">
        <SEO {...seoData} />
        <Typography variant="h2" gutterBottom className="text-3xl font-bold mb-6">Blog Posts</Typography>
        <Grid container spacing={3}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
              <Card className="h-full">
                <Skeleton variant="rectangular" height={200} />
                <CardContent>
                  <Skeleton variant="text" height={40} />
                  <Skeleton variant="text" height={20} />
                  <Skeleton variant="text" height={20} />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  if (error) {
    Sentry.captureException(error);
    return <Typography color="error">Error loading blog posts</Typography>;
  }

  const posts = data?.data?.publication?.posts?.edges || [];

  return (
    <Box className="container mx-auto px-4 py-8">
      <SEO {...seoData} />
      <Typography variant="h2" gutterBottom className="text-3xl font-bold mb-6">Blog Posts</Typography>
      <Grid container spacing={3}>
        {posts.map((post) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={post.node.url}>
            <Card className="h-full flex flex-col">
              {post.node.coverImage && (
                <Box className="h-48 overflow-hidden">
                  <img
                    src={post.node.coverImage.url}
                    alt={post.node.title}
                    className="w-full h-full object-cover"
                    loading="lazy"
                  />
                </Box>
              )}
              <CardContent className="flex-grow">
                <Typography variant="h6" component="div" className="font-bold mb-2">
                  {post.node.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" className="mb-4">
                  {post.node.brief}
                </Typography>
              </CardContent>
              <CardActions>
                <Button 
                  size="small" 
                  component={Link} 
                  to={`/blog/${post.node.url.split('/').pop()}`}
                  variant="contained"
                  fullWidth
                >
                  Read More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Blog;