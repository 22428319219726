import React from "react";
import { BrowserRouter, Routes, Route, useRouteError } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import BlogPost from "./pages/BlogPost";
import ProjectDetails from "./pages/ProjectDetails";
import Sitemap from "./pages/Sitemap";
import ErrorBoundary from "./components/ErrorBoundary";
import NotFound from "./components/NotFound";
import Guestbook from "./pages/Guestbook";
import CommentPolicy from "./pages/CommentPolicy";
import BuildInfo from "./pages/BuildInfo";
import ResumeBuilder from "./pages/ResumeBuilder";
import * as Sentry from "@/overrides/sentry.override";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#00ff00",
    },
    background: {
      default: "#000000",
      paper: "#111111",
    },
  },
});

const queryClient = new QueryClient();

const ErrorElement = () => {
  const error = useRouteError();
  Sentry.captureException(error);
  return <h1>Oops! Something went wrong.</h1>;
};

const SitemapWrapper = () => {
  const sitemap = Sitemap();
  return new Response(sitemap, {
    status: 200,
    headers: {
      'Content-Type': 'application/xml',
    },
  });
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<Home />} errorElement={<ErrorElement />} />
        <Route path="about" element={<About />} errorElement={<ErrorElement />} />
        <Route path="projects" element={<Projects />} errorElement={<ErrorElement />} />
        <Route path="projects/:id" element={<ProjectDetails />} errorElement={<ErrorElement />} />
        <Route path="blog" element={<Blog />} errorElement={<ErrorElement />} />
        <Route path="blog/:slug" element={<BlogPost />} errorElement={<ErrorElement />} />
        <Route path="contact" element={<Contact />} errorElement={<ErrorElement />} />
        <Route path="guestbook" element={<Guestbook />} errorElement={<ErrorElement />} />
        <Route path="comment-policy" element={<CommentPolicy />} errorElement={<ErrorElement />} />
        <Route path="build-info" element={<BuildInfo />} errorElement={<ErrorElement />} />
        <Route path="resume-builder" element={<ResumeBuilder />} errorElement={<ErrorElement />} />
        <Route path="sitemap.xml" element={<SitemapWrapper />} errorElement={<ErrorElement />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

const App = () => {
  return (
    <React.StrictMode>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <ThemeProvider theme={darkTheme}>
              <CssBaseline />
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </ThemeProvider>
          </HelmetProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

export default App;