import { getCurrentScope } from "@sentry/browser";
import { captureConsoleIntegration } from "@sentry/integrations";
import type { BrowserOptions } from "@sentry/react";
import {
  init,
  replayIntegration
} from "@sentry/react";

const SentryInit = () => {
  const options: BrowserOptions = {
    dsn: "https://5ee37c41e37bf58e1de29c02e106906f@o4505487881732096.ingest.us.sentry.io/4508064310558720",
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    profilesSampleRate: 1.0,
  };

  const integrations = [
    replayIntegration(),
    captureConsoleIntegration({
      levels: ["error"],
    }),
  ];

  options.integrations = integrations;

  init(options);

  getCurrentScope().setTags({});
};

SentryInit();

export * from "@sentry/react";