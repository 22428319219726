import { List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import { Home, Person, Work, Book, Mail, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ onSearchOpen }) => {
  const navigate = useNavigate();

  const menuItems = [
    { text: 'Home', icon: <Home />, path: '/' },
    { text: 'About', icon: <Person />, path: '/about' },
    { text: 'Projects', icon: <Work />, path: '/projects' },
    { text: 'Blog', icon: <Book />, path: '/blog' },
    { text: 'Contact', icon: <Mail />, path: '/contact' },
  ];

  return (
    <>
      <List>
        {menuItems.map((item) => (
          <ListItem 
            button 
            key={item.text} 
            onClick={() => navigate(item.path)}
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              },
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <IconButton
        color="primary"
        aria-label="open search"
        onClick={onSearchOpen}
        sx={{ position: 'absolute', bottom: 16, left: 16 }}
      >
        <Search />
      </IconButton>
    </>
  );
};

export default Sidebar;